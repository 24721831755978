import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Obtener,Save,Editar,SaveList} from '@/Generico/Conexion';
import {mainAxios} from '@/plugins/axios'
class Placa
{
    placaId?:number =0;
    transportistaId?:number =0;
    descripcion?:string="";
    estado?:boolean = true;

    constructor(placaId:number,descripcion:string,transportistaId:number,estado:boolean)
    {
        this.placaId = placaId;
        this.descripcion = descripcion;
        this.transportistaId = transportistaId;
        this.estado = estado;
    }
    
}


async function ObtenerPlaca()
{
    return await Obtener<Placa>("Placa/Obtener",mainAxios);
}
async function ObtenerPlacaIdTransporte(tranposrtistaId:number)
{
    return await Obtener<Placa>(`Placa/ObtenerIdTransporte/${tranposrtistaId}`,mainAxios);
}
async function ObtenerSelectPlaca()
{
    return await Obtener<Placa>(`Placa/ObtenerSelect/`,mainAxios);
}
 async function  GuardarPlaca(placa:Placa):Promise<ResponseGenerico>
{
    return await Save<Placa>("Placa/Guardar",placa,mainAxios);
} 

async function  GuardaListadoPlaca(placa:Placa[]):Promise<ResponseGenerico>
{
    return await SaveList<Placa>("Placa/GuardarListado",placa,mainAxios);
} 

async function  EditarPlaca(placa:Placa):Promise<ResponseGenerico>
{
    return await Editar<Placa>("Placa/Editar",placa,mainAxios);
}

async function  EditarPlacaEstado(placa:Placa):Promise<ResponseGenerico>
{
    return await Editar<Placa>("Placa/EditarEstado",placa,mainAxios);
}

export 
{
    
    Placa,ObtenerPlaca,ObtenerPlacaIdTransporte,ObtenerSelectPlaca,GuardaListadoPlaca,GuardarPlaca,
    EditarPlaca,
    EditarPlacaEstado
};
