


































































import { Vue, Component } from "vue-property-decorator";
import Contratista, { ContratistaPo } from "@/entidades/Plantacion/Contratista";
import { ObtenerPlaca, Placa } from "@/entidades/Plantacion/Placa";

import {
  RecepcionTerceroRequest,
  GuardarHeader,
} from "@/entidades/KTB/RegistroInformacion/RecepcionTercero";
import { Mutation, State } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import { Planta } from "@/entidades/Maestro/Planta";
@Component({
  name: "RecepcionMaderaTerceroHeader",
})
export default class RecepcionMaderaTerceroHeader extends Vue {
  @State("plantaAsignada", { namespace: "authModule" }) plantas!: Array<Planta>;
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;
  @State("sistemaId", { namespace: "authModule" }) sistemaId!: number;
  @State("usuarioId", { namespace: "authModule" }) usuarioId!: string;
  contratistaModelo: Contratista = new Contratista();
  listadoContratista: Contratista[] = [];
  listadoContratistaPo: ContratistaPo[] = [];
  listadoPlaca: Placa[] = [];
  placaId: number = 0;
  contratistaId: number = 0;
  contratistaPoId: number = 0;
  guiaForestal: string = "";
  plantaId: number = 0;
  tipoContratista: number = 0;
  placaDescripcion: string = "";
  async ObtenerContratista() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let response = await Contratista.ObtenerSelect(3);
      if (response.isSuccess == true) {
        this.listadoContratista = response.isResult;
        //   this.contratistaId = this.listadoContratista[0].contratistaId;
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 1000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async ObtenerContratistaPo() {

    if(this.plantaId !== null && this.contratistaId !== null && this.plantaId > 0 && this.contratistaId > 0)
      {
        try {
      
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let response = await Contratista.ObtenerPo(this.contratistaId,this.plantaId);
      if (response.isSuccess == true) {
        this.listadoContratistaPo = response.isResult;
        this.contratistaPoId = this.listadoContratistaPo[0].contratistaPoId;
        this.tipoContratista = this.listadoContratista.find(
          (x) => x.contratistaId == this.contratistaId
        )?.tipo!;
      } else {
        this.listadoContratistaPo = [];
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 1000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
      }
    
  }

  async ObtenerPlaca() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let response = await ObtenerPlaca();
      if (response.isSuccess == true) {
        this.listadoPlaca = response.isResult;
        this.placaId = this.listadoPlaca[0].placaId!;
      } else {
        this.listadoPlaca = [];
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 1000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }
  async guardarRecepcionManual() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let request: RecepcionTerceroRequest = new RecepcionTerceroRequest();
      request.plantaId = this.plantaId;
      request.po = this.listadoContratistaPo.find((x) => x.contratistaPoId == this.contratistaPoId)!.poSimple;
      request.material = this.listadoContratistaPo.find((x) => x.contratistaPoId == this.contratistaPoId)!.material;
      request.placaId = this.tipoContratista == 1 ? this.placaId : 0;
      request.proveedorId = this.contratistaId;
      request.usuarioId = this.usuarioId;
      request.guiaForestal = this.guiaForestal;
      request.placaDescripcion = this.placaDescripcion;
      let response = await GuardarHeader(request);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 3000));
        this.$emit("limpiar");
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 3000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  get validarGuardar() {
    if (
      this.plantaId > 0 &&
      this.contratistaId > 0 &&
      this.contratistaPoId > 0 &&
      this.placaId > 0
    )
      return false;
    else return true;
  }
  mounted() {
    this.ObtenerContratista();
    this.ObtenerPlaca();
  }
}
